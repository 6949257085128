import Debug from 'debug';
import { createHashHistory } from 'history';
import queryString from 'query-string';

import { BackButtonListener } from '@capacitor/app';

const debug = Debug('history');

const history = createHashHistory();

// used by cypress (and possibly when debugging things)
window.routerHistory = history;

/**
 * Whenever there are changes made
 * to our history, we want to keep track of those
 * in our own history stack.
 *
 * It keeps the last 50 visited paths within ovos play
 * in memory.
 *
 * In our custom "back" logic, we can then use the information
 * in this stack to determine to which path we should go back to,
 * or if we should use a fallback path.
 *
 * There is no reliable way to detect whether a user can go back in
 * the history or whether the user came from a different app, reloaded the page, etc
 * via browser builtin methods.
 *
 * Our custom logic here is made so that it can be simply reused everywhere in our app code,
 * without having to worry about the details of the history stack (eg history length, previous page, fallback etc).
 *
 * https://stackoverflow.com/a/26707577/1856221
 * https://stackoverflow.com/a/3588420/1856221
 * https://github.com/remix-run/history/issues/582
 * https://github.com/remix-run/history/issues/873
 * https://github.com/remix-run/history/issues/573#issuecomment-476919608
 * https://github.com/remix-run/history/issues/277#issuecomment-213451361
 * https://github.com/remix-run/react-router/pull/843#issuecomment-76477589
 * https://github.com/remix-run/history/issues/36
 * https://github.com/remix-run/history/issues/334#issuecomment-244250026
 * https://github.com/remix-run/react-router/pull/1376#issuecomment-118185067
 *
 * Theoretically the history stack could be persisted in localStorage.
 * However, this could lead to a plethora of different bugs when using multiple tabs.
 */
const historyStack = [history.location.pathname];
let previousPathname = undefined;

history.listen(({ location, action }) => {
  debug(`🔍 History change: ${location.pathname}${location.search || ''} (${action})`);
  // if the location is replaced, we also replace the latest location in our history stack
  if (action === 'REPLACE') {
    historyStack[0] = `${location.pathname}${location.search}`;
  } else if (historyStack[0] !== `${location.pathname}${location.search || ''}`) {
    // if a new history item is popped or pushed, we add it to our internal history stack
    historyStack.unshift(`${location.pathname}${location.search}`);

    // keep only the latest 50 items in our custom history stack (50 is also the max for browser history sessions)
    if (historyStack.length >= 52) {
      historyStack.splice(50, 2);
    }
  }
});

/**
 * Custom "back" logic, when using the android back button.
 */
export const handleCustomBack: BackButtonListener = (event) => {
  /**
   * If we can't go back (only on android),
   * we push the user to '/' instead.
   *
   * If our custom history stack contains less than 2 elements,
   * we also just navigate back to home.
   */
  if (!event.canGoBack || historyStack.length < 2) {
    debug('🔙 No previous path to go back to, going to home instead (Android Handler)');
    history.push('/');
  } else {
    const [current, previous] = historyStack.splice(0, 2);
    previousPathname = current;
    debug(`⏩ From ${history.location.pathname} (stored: ${current}) => ${previous}`);
    history.push(previous);
  }
};

const printHistoryStack = () => {
  return `📜 Stack: ${historyStack.join(' <- ')} <- 🏁`;
};

export const useBackWithFallback = (hookBackFallback: (() => any) | string = '/') => {
  const triggerFallback = (fb: Parameters<typeof useBackWithFallback>[0]) => {
    if (historyStack[0] === `${history.location.pathname}${history.location.search || ''}`) {
      // remove the latest history stack entry, which should be the current page
      // because when we move "back" with the apps back button, we don't have to be able to move forward again
      // if we kept the current page, another "goBack" would just bring us back to the current page and we'd end up in a loop
      previousPathname = historyStack.splice(0, 1)[0];
    }

    if (typeof fb === 'string') {
      history.push(fb);
    } else if (typeof fb === 'function') {
      fb();
    }
  };

  /**
   * The `goBack` function is responsible for
   * checking the current history stack and
   * navigating back to the previous page if there is one available.
   */
  const goBack = (fallback: Parameters<typeof triggerFallback>[0]) => {
    debug('⏩ Executing history back with fallback...');
    debug(printHistoryStack());

    // if we have some data in our stack to go back to, use it!
    if (historyStack.length >= 2) {
      // retrieve the previous page and delete the current + previous page from the stack
      // upon history.push, the "previous" page will be added to the stack again by the history listener
      const [current, previous] = historyStack.splice(0, 2);
      previousPathname = current;
      debug(`⏩ From ${history.location.pathname} => ${previous}`);
      history.push(previous);
    } else {
      debug('⏩ No previous page in history stack - triggering fallback');
      triggerFallback(fallback || hookBackFallback);
    }

    if (debug.enabled) {
      setTimeout(() => {
        debug(printHistoryStack());
      }, 255);
    }
  };

  return [goBack, historyStack, previousPathname] as const;
};

export const removeQueryFromUrl = () => {
  history.push({
    search: '',
  });

  /**
   * To ensure to also clear search parameters before the # we have to replaceState on the original history object
   * Otherwise it would only remove parameters after #
   */
  window.history.replaceState({}, window.document.title, `/#${history.location.pathname}`);
};

/**
 * query-string library will not recognize query parameters placed after #
 * So we use our own logic to extract the query params a url.
 */
export const getQueryParamsFromUrl = (url = location.href): { [key: string]: string } => {
  const queryMatch = url.match(/\?([\w=&\-_.%/]*)/);
  let qs = '';
  let query = {};

  if (queryMatch) {
    qs = queryMatch[0];
    query = queryString.parse(qs, { decode: true }) as { [key: string]: string };
  }

  return query;
};

export default history;
